import * as React from "react";
import HeaderDesktop from "src/components/headers/header-desktop";
import HeaderMobile from "src/components/headers/header-mobile";
import styled from "styled-components";
import { generateMedia } from "styled-media-query";

const mediaDesktop = generateMedia({
  tablet: "1000px"
}).greaterThan("tablet");

const mediaMobile = generateMedia({
  tablet: "1000px"
}).lessThan("tablet");

export interface Props {
  past_infomations: Array<{
    shorthand: string;
    url: string;
  }>;
  categories: Array<{
    name: string;
    id: string;
  }>;
  logo_image: string;
  title: string;
  simple?: boolean;
  basecolor?: string;
  pageurl: string;
}

const Header: React.FunctionComponent<Props> = props => {
  return (
    <div id="header">
      <SpOnly>
        <HeaderMobile {...props}></HeaderMobile>
      </SpOnly>
      <PcOnly>
        <HeaderDesktop {...props}></HeaderDesktop>
      </PcOnly>
    </div>
  );
};

Header.displayName = "Header";

export default Header;

const PcOnly = styled.div`
  ${mediaMobile`
    display: none;
  `}

  ${mediaDesktop`
    display:block;
  `}
`;

const SpOnly = styled.div`
  ${mediaMobile`
    display: block;
  `}
  ${mediaDesktop`
    display:none;
  `}
`;
