import React from "react";
import Image from "src/components/image";

interface ImageTagProps {
  src: string;
  alt?: string;
  style?: any;
  key?: string;
  isPreview?: boolean;
}
export const ImageTag = (props: ImageTagProps) => {
  // 画像の縮小機能は一旦にOFFにすることにした。
  // return props.isPreview ? <img {...props}></img> : <Image {...props}></Image>;
  return <img {...props}></img>;
};
