import { Link } from "gatsby";
import * as React from "react";
import { slide as Menu, Styles } from "react-burger-menu";
import onClickOutside from "react-onclickoutside";
import { ExternalSitePath, InternalSitePath } from "src/constant";
import { scrollTo, ShareLinkHelper } from "src/helper/link-helper";
import styled from "styled-components";
import { BaseColorProps } from "src/interfaces";
import { ColorHelper } from "src/helper/color-helper";

interface State {
  listOpen: boolean;
  shareOpen: boolean;
}

interface Props {
  past_infomations: Array<{
    shorthand: string;
    url: string;
  }>;
  categories: Array<{
    name: string;
    id: string;
  }>;
  basecolor?: string;
  pageurl: string;
}

class HumbergerMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      listOpen: false,
      shareOpen: false
    };
  }

  public toggleList() {
    this.setState(prevState => ({
      listOpen: !prevState.listOpen
    }));
  }

  public handleScroll() {
    if (this.state.listOpen === true) {
      this.setState({
        listOpen: false
      });
    }
  }
  public handleClickOutside() {
    this.setState({
      listOpen: false
    });
  }

  public handleStateChange(state: any) {
    this.setState({ listOpen: state.listOpen });
  }

  public async componentDidMount() {
    window.addEventListener("scroll", this.handleScroll.bind(this), false);
  }

  public componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  public render() {
    const pastInfo = this.props.past_infomations;
    const baseColor = this.props.basecolor ? this.props.basecolor : "#50aafc";
    const baseColor20 = ColorHelper.convertToRGBAString(baseColor, "0.2");
    return (
      <Menu
        customCrossIcon={false}
        customBurgerIcon={<img src={`${InternalSitePath.image_base}menu.svg`}></img>}
        styles={styles}
        width={"192px"}
        isOpen={this.state.listOpen}
        onStateChange={state => this.handleStateChange(state)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "192px"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              borderRadius: "4px",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#A4A4A4",
              width: "160px",
              height: "32px",
              margin: "16px",
              cursor: "pointer"
            }}
            onClick={() => this.setState({ shareOpen: !this.state.shareOpen })}
          >
            <img src={`${InternalSitePath.image_base}share.svg`} width="16px" height="16px"></img>
            <p style={{ color: "white", fontSize: "10px" }}>このページをシェア</p>
          </div>
          {this.state.shareOpen ? (
            <div>
              <ShareLink
                basecolor={baseColor20}
                href={ShareLinkHelper.getTwitterShareLink(`${ExternalSitePath.share_link}${this.props.pageurl}`, "", "")}
                target="_blank"
              >
                <img src={`${InternalSitePath.image_base}Twitter.svg`}></img>
              </ShareLink>
              <ShareLink
                basecolor={baseColor20}
                href={ShareLinkHelper.getFacebookShareLink(`${ExternalSitePath.share_link}${this.props.pageurl}`)}
                target="_blank"
              >
                <img src={`${InternalSitePath.image_base}Facebook.svg`}></img>
              </ShareLink>
              <ShareLink
                basecolor={baseColor20}
                href={ShareLinkHelper.getLineShareLink(`${ExternalSitePath.share_link}${this.props.pageurl}`)}
                target="_blank"
              >
                <img src={InternalSitePath.image_base + "line-share.png"} width="89px" height="24px"></img>
              </ShareLink>
            </div>
          ) : null}
          <div style={{ border: "1px solid #EBEDF2", display: "block" }}></div>
          {this.props.categories.map((value, index) => {
            return (
              <InnerLinkStyled
                key={index}
                href="#"
                onClick={(e: any) => {
                  this.handleClickOutside();
                  scrollTo("#" + value.id);
                  e.preventDefault();
                }}
              >
                <MenuItemText>{value.name}</MenuItemText>
              </InnerLinkStyled>
            );
          })}
          <div style={{ marginBottom: "16px" }}></div>
          <hr></hr>
          {pastInfo.length > 0 && <MenuItemText>過去の出展情報</MenuItemText>}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              width: "192px",
              marginLeft: "12px"
            }}
          >
            {pastInfo.map((info, index) => (
              <MenuItemPast basecolor={baseColor} target="_blank" key={index} href={info.url}>
                {info.shorthand}
              </MenuItemPast>
            ))}
          </div>
        </div>
      </Menu>
    );
  }
}

const styles: Styles = {
  bmBurgerButton: {
    position: "absolute",
    width: "36px",
    height: "56px",
    left: "10px"
  },
  bmCross: {},
  bmCrossButton: {},
  bmBurgerBars: {},
  bmMenuWrap: {
    position: "fixed",
    height: "600px",
    top: "56px"
  },
  bmMenu: {
    background: "#ffffff",
    fontSize: "1.15em"
  },
  bmMorphShape: {
    fill: "#373a47"
  },
  bmItemList: {
    color: "#b8b7ad"
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
    marginTop: "56px"
  }
};

const LinkStyled = styled(Link)`
  text-decoration: none;
  color: #6c6c6c;
`;

const InnerLinkStyled = styled.a`
  text-decoration: none;
  color: #6c6c6c;
`;

const ShareLink = styled.a`
  display: block;
  width: 100%;
  text-align: center;
  &:hover {
    background-color: ${(props: BaseColorProps) => props.basecolor};
  }
`;

const MenuItemText = styled.div`
  color: #6c6c6c;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  margin: 16px 16px 0px;
`;

const MenuItemPast = styled.a`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 34px;
  background-color: #c4c4c4;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin: 4px;
  text-decoration: none;
  &:hover {
    background-color: ${(props: BaseColorProps) => props.basecolor};
  }
`;

export default onClickOutside(HumbergerMenu);
