import * as React from "react";
import onClickOutside from "react-onclickoutside";
import { ShareTips } from "src/components/share-tips";
import { InternalSitePath } from "src/constant";
import styled from "styled-components";

interface State {
  listOpen: boolean;
}

interface Props {
  basecolor?: string;
  pageurl: string;
}

class ShareButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      listOpen: false
    };
  }

  public toggleList(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    this.setState(prevState => ({
      listOpen: !prevState.listOpen
    }));
    e.preventDefault();
  }

  public handleClickOutside() {
    this.setState({
      listOpen: false
    });
  }

  public render() {
    const { listOpen } = this.state;

    return (
      <Wrapper>
        <MenuButton onClick={this.toggleList.bind(this)}>
          <MenuImage src={InternalSitePath.image_base + "share.svg"} />
        </MenuButton>
        {listOpen && (
          <div
            style={{
              position: "absolute",
              top: "55px",
              right: "-47.5px",
              zIndex: 100
            }}
          >
            <ShareTips
              pageurl={this.props.pageurl}
              headText="このページをシェア"
              basecolor={this.props.basecolor}
              direction="bottom"
            ></ShareTips>
          </div>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  position: relative;
  vertical-align: center;
  width: 40px;
`;

const MenuButton = styled.a`
  text-decoration: none;
`;

const MenuImage = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

export default onClickOutside(ShareButton);
