import * as React from "react";
import { ExternalSitePath, InternalSitePath } from "src/constant";
import { ShareLinkHelper } from "src/helper/link-helper";
import styled from "styled-components";
import { BaseColorProps } from "src/interfaces";
import { ColorHelper } from "src/helper/color-helper";
interface Props {
  direction: "bottom" | "top";
  pageurl: string;
  product?: {
    id: string;
    kind: string;
    title: string[];
    twitter_hashes: string[];
  };
  basecolor?: string;
  headText: string;
}

export class ShareTips extends React.Component<Props> {
  public render() {
    return this.props.direction === "bottom" ? (
      <ArrowBox>{this.renderContent()}</ArrowBox>
    ) : (
      <ArrowBoxTop>{this.renderContent()}</ArrowBoxTop>
    );
  }

  private renderContent() {
    const product = this.props.product;
    const basecolor20 = this.props.basecolor ? ColorHelper.convertToRGBAString(this.props.basecolor, "0.2") : "#e5e5e5";
    const productLink = `${ExternalSitePath.share_link}${this.props.pageurl}/${product != null ? `#${product.id}` : ""}`;
    const productText = product != null ? `${product.kind} ${product.title.join(" ")}` : "";
    const hashTagText = product != null ? product.twitter_hashes.join(",") : "";

    return (
      <InBoxContainer>
        <HeaderText>{this.props.headText}</HeaderText>

        <ShareLink
          basecolor={basecolor20}
          href={ShareLinkHelper.getTwitterShareLink(productLink, productText, hashTagText)}
          target="_blank"
        >
          <img src={InternalSitePath.image_base + "Twitter.svg"}></img>
        </ShareLink>
        <ShareLink basecolor={basecolor20} href={ShareLinkHelper.getFacebookShareLink(productLink)} target="_blank">
          <img src={InternalSitePath.image_base + "Facebook.svg"}></img>
        </ShareLink>
        <ShareLink basecolor={basecolor20} href={ShareLinkHelper.getLineShareLink(productLink)} target="_blank">
          <img src={InternalSitePath.image_base + "line-share.png"} width="89px" height="24px"></img>
        </ShareLink>
      </InBoxContainer>
    );
  }
}

const InBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  align-items: center;
`;

const HeaderText = styled.div`
  font-size: 12px;
  line-height: 34px;
  color: #a4a4a4;
`;

const ShareLink = styled.a`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 2px 0px;
  padding-left: 20%;
  &:hover {
    background-color: ${(props: BaseColorProps) => props.basecolor};
  }
`;

const ArrowBox = styled.div`
  position: relative;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  width: 144px;
  height: 132px;
  &::before,
  &::after {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &::after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 10px;
    margin-left: -10px;
  }

  &::before {
    border-color: rgba(229, 229, 229, 0);
    border-bottom-color: #e5e5e5;
    border-width: 11px;
    margin-left: -11px;
  }
`;

const ArrowBoxTop = styled.div`
  position: relative;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  width: 144px;
  height: 132px;
  &::before,
  &::after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &::after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #ffffff;
    border-width: 10px;
    margin-left: -10px;
  }

  &::before {
    border-color: rgba(229, 229, 229, 0);
    border-top-color: #e5e5e5;
    border-width: 11px;
    margin-left: -11px;
  }
`;
