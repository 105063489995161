import * as React from "react";
import PastList from "src/components/headers/past-list";
import ShareButton from "src/components/headers/share-button";
import { scrollTo } from "src/helper/link-helper";
import styled from "styled-components";
import { BaseColorProps } from "src/interfaces";

interface Props {
  past_infomations: Array<{
    shorthand: string;
    url: string;
  }>;
  categories: Array<{
    name: string;
    id: string;
  }>;
  logo_image: string;
  title: string;
  simple?: boolean;
  basecolor?: string;
  pageurl: string;
}

const HeaderDesktop: React.SFC<Props> = props => {
  const baseColor = props.basecolor ? props.basecolor : "#50aafc";
  return (
    <Wrapper id="header" basecolor={baseColor}>
      <Container>
        <div
          className="leftContainer"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <img
            src={props.logo_image}
            style={{
              marginLeft: "18px",
              width: "90px",
              objectFit: "contain"
            }}
          ></img>
          <div
            style={{
              fontSize: "32px",
              lineHeight: "34px",
              color: "#FFFFFF",
              marginLeft: "48px",
              marginRight: "48px",
              fontFamily: "Helvetica",
              fontWeight: "bold"
            }}
            className="header-title"
            dangerouslySetInnerHTML={{ __html: props.title }}
          ></div>
        </div>
        {props.simple !== true && (
          <div
            className="rightContainer"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "50%",
              padding: "0px 20px 0px 0px"
            }}
          >
            {props.categories.map((value, index) => {
              return (
                <MenuItem key={index}>
                  <InnerLinkStyled
                    href={`#`}
                    onClick={(e: any) => {
                      scrollTo("#" + value.id);
                      e.preventDefault();
                    }}
                  >
                    {value.name}
                  </InnerLinkStyled>
                </MenuItem>
              );
            })}
            <ShareButton pageurl={props.pageurl} basecolor={baseColor}></ShareButton>
            {props.past_infomations.length > 0 && <PastList basecolor={baseColor} past_infomations={props.past_infomations}></PastList>}
          </div>
        )}
      </Container>
    </Wrapper>
  );
};

HeaderDesktop.displayName = "HeaderDesktop";

export default HeaderDesktop;

const Wrapper = styled.header`
  width: 100%;
  height: 72px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
  padding: 0;
  background-color: ${(props: BaseColorProps) => props.basecolor};
`;

const Container = styled.header`
  width: 100%;
  max-width: 1280px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  padding: 0;
`;

const MenuItem = styled.div`
  margin: 5px;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  text-align: center;
  align-items: center;
  color: #ffffff;
  height: 72px;
`;

const InnerLinkStyled = styled.a`
  text-decoration: none;
  color: white;
`;
