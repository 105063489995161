import { generateMedia } from "styled-media-query";

export const ExternalSitePath = {
  pixiv_userpage_base: `https://www.pixiv.net/member.php?id=`,
  pixiv_past_comike_base: `https://goods.pixiv.net`,
  share_link: `https://goods.pixiv.net`
};

export const InternalSitePath = {
  image_base: "/images/"
};

export const mobileBreakPointQuery = {
  for_react_media: "(max-width: 1000px)",
  for_styled_media: generateMedia({
    tablet: "1000px"
  })
};
