import * as React from "react";
import onClickOutside from "react-onclickoutside";
import { InternalSitePath } from "src/constant";
import styled from "styled-components";
import { BaseColorProps } from "src/interfaces";

interface State {
  listOpen: boolean;
}

interface Props {
  past_infomations: Array<{
    url: string;
    shorthand: string;
  }>;
  basecolor?: string;
}

class PastList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      listOpen: false
    };
  }

  public toggleList(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    this.setState(prevState => ({
      listOpen: !prevState.listOpen
    }));
    e.preventDefault();
  }

  public handleClickOutside() {
    this.setState({
      listOpen: false
    });
  }

  public render() {
    const { listOpen } = this.state;
    const pastInfos = this.props.past_infomations;
    const baseColor = this.props.basecolor ? this.props.basecolor : "#50aafc";
    return (
      <Wrapper>
        <a style={{ textDecoration: "none" }} onClick={this.toggleList.bind(this)}>
          <PastListBox basecolor={baseColor}>過去の出典情報</PastListBox>
        </a>
        {listOpen && (
          <div
            style={{
              position: "absolute",
              top: "55px",
              left: "10px",
              width: "200px",
              zIndex: 100
            }}
          >
            <ArrowBox>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "95%",
                  margin: "8px 0px 8px 2px",
                  gridTemplateColumns: "repeat(auto-fit, 32px)"
                }}
              >
                {pastInfos.map((past, index) => (
                  <MenuItem basecolor={baseColor} target="blank" key={index} href={past.url}>
                    {past.shorthand}
                  </MenuItem>
                ))}
              </div>
            </ArrowBox>
          </div>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  position: relative;
  vertical-align: center;
  width: 168px;
`;

const PastListBox = styled.div`
  width: 160px;
  height: 40px;
  background-color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${(props: BaseColorProps) => props.basecolor};
  font-size: 16px;
  line-height: 34px;
  cursor: pointer;
`;

const MenuItem = styled.a`
  font-size: 12px;
  line-height: 18px;
  /* or 283% */

  display: flex;
  align-items: center;
  text-align: center;
  margin: 6px 2px;
  text-decoration: none;
  color: #a4a4a4;
  &:hover {
    text-decoration: underline;
    color: ${(props: BaseColorProps) => props.basecolor};
  }
`;

const ArrowBox = styled.div`
  position: relative;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  width: 144px;
  min-height: 120px;
  &::before,
  &::after {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &::after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 10px;
    margin-left: -10px;
  }
  &::before {
    border-color: rgba(229, 229, 229, 0);
    border-bottom-color: #e5e5e5;
    border-width: 11px;
    margin-left: -11px;
  }
`;

export default onClickOutside(PastList);
