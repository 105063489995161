import * as React from "react";
import HumbergerMenu from "src/components/headers/humberger-menu";
import styled from "styled-components";
import { BaseColorProps } from "src/interfaces";

interface Props {
  past_infomations: Array<{
    shorthand: string;
    url: string;
  }>;
  categories: Array<{
    name: string;
    id: string;
  }>;
  logo_image: string;
  title: string;
  simple?: boolean;
  basecolor?: string;
  pageurl: string;
}

const HeaderMobile: React.SFC<Props> = props => {
  return (
    <Wrapper id="header" basecolor={props.basecolor ? props.basecolor : "#50aafc"}>
      {props.simple !== true && (
        <HumbergerMenu
          basecolor={props.basecolor ? props.basecolor : "#50aafc"}
          past_infomations={props.past_infomations}
          categories={props.categories}
          pageurl={props.pageurl}
        />
      )}
      <LogoWrapper>
        <img height="24px" src={props.logo_image} style={{ marginBottom: "2px" }}></img>
        <div
          style={{
            fontSize: "16px",
            lineHeight: "34px",
            fontWeight: "bold",
            color: "#ffffff",
            verticalAlign: "center",
            alignContent: "center",
            marginLeft: "8px"
          }}
          className="header-title"
        >
          {props.title}
        </div>
      </LogoWrapper>
    </Wrapper>
  );
};

HeaderMobile.displayName = "Header";

export default HeaderMobile;

const Wrapper = styled.header`
  width: 100%;
  background-color: ${(props: BaseColorProps) => props.basecolor};
  height: 56px;
  position: relative;
`;

const LogoWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
  padding: 0;
  height: 56px;
`;
