export class ShareLinkHelper {
  public static getTwitterShareLink(url: string, text: string, hashtags: string) {
    url = encodeURIComponent(url);
    text = encodeURIComponent(text);
    hashtags = encodeURIComponent(hashtags);
    return `https://twitter.com/intent/tweet?url=${url}&text=${text}&hashtags=${hashtags}`;
  }

  public static getFacebookShareLink(url: string) {
    url = encodeURIComponent(url);
    return `https://www.facebook.com/sharer/sharer.php?u=${url}`;
  }

  public static getPawooShareLink(url: string, text: string, hashtags: string) {
    url = encodeURIComponent(url);
    text = encodeURIComponent(text);
    hashtags = encodeURIComponent(hashtags);
    return `https://pawoo.net/share?text=${url}${text}${hashtags}`;
  }

  public static getLineShareLink(url: string) {
    url = encodeURIComponent(url);
    return `https://social-plugins.line.me/lineit/share?url=${url}`;
  }
}

export const scrollTo = (id: string) => {
  const el = document.querySelector(id);
  if (el) {
    return window.scrollTo(0, el.offsetTop - 20);
  }
  return false;
};
